<template>
  <div
    class="dashboard-header"
    :class="{
      'dashboard-header--scrolled': isScrolled && mobileHeaderFixed,
      'dashboard-header--fixed': mobileHeaderFixed,
    }"
  >
    <button v-if="isAuth" class="dashboard-header__menu" @click="$emit('open-menu')">
      <UIIcon name="menu" />
    </button>
    <UILogo
      :to="{
        name: 'dashboard',
      }"
    />
    <NuxtLink v-if="isAuth" no-prefetch class="dashboard-header__user" to="/profile">
      <button>
        <UIIcon name="user" />
      </button>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import useUIStore from '~/store/ui'
import useAuthStore from '~/store/auth'

defineEmits<{
  (e: 'open-menu'): void
}>()

const { isAuth } = storeToRefs(useAuthStore())

const isScrolled = ref(false)

const UIStore = useUIStore()

const { mobileHeaderFixed } = storeToRefs(UIStore)

const handleScroll = () => {
  isScrolled.value = window.scrollY > 0
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  handleScroll()
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.dashboard-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $bg;
  padding: 12px 16px;
  z-index: 999;
  box-shadow: 0 0 0 0 transparent;
  transition: box-shadow $base-transition;
  position: absolute;

  .logo {
    margin: 0 auto;
  }

  &--fixed {
    position: fixed;
  }

  &__user {
    padding: 3px;
    border-radius: 8px;
    border: 1px solid transparent;
    transition:
      $base-transition background-color,
      $base-transition border-color;

    &.router-link-active {
      background-color: $gold-primary-50;
      border: 1px solid $gold-primary-120;
    }
  }

  &--scrolled {
    box-shadow: $box-shadow-down;
  }

  .logo {
    color: $black-100;
    display: flex;
  }

  button {
    border: none;
    color: $black-100;
    padding: 0;
    height: 24px;
    display: flex;
  }
}
</style>
